import { Button } from 'antd'
import styled from 'styled-components'

export const CookiesBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
`

export const CookiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 38px;
  right: 30px;
  padding: 24px 40px;
  z-index: 9;
  max-width: 500px;
  background: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  @media (max-width: 550px) {
    & {
      right: 0;
      left: 0;
      bottom: 0;
      max-width: unset;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`

export const CookiesTitle = styled.div`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #393939;
`

export const CookiesDescription = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  margin-top: 24px;

  a {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: #f16749;

    @media (max-width: 550px) {
      & {
        display: block;
      }
    }
  }
`

export const CookiesButton = styled(Button)`
  margin-top: 28px;
`
