import { Image, Space } from 'antd'
import styled from 'styled-components'

export const StyledLegalWarning = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(241, 103, 73, 0.2), rgba(241, 103, 73, 0.2)), #ffffff;

  & > .ant-space-item {
    z-index: 9;
  }

  & > .ant-space-item:first-child {
    margin-right: 0px !important;
  }
`

export const StyledLegalWarningWrapper = styled.div`
  width: 100%;
  max-width: 1162px;
  margin: auto;
  height: 120%;
  display: flex;
  background: #fef3f1;
  border-radius: 50%;
  margin: -50px 0;
  z-index: 9;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 80%;
  }
`

export const StyledLegalWarningInnerWrapper = styled.div`
  max-width: 446px;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .legar-warning-title {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #393939;
    margin-top: 70px;
    margin-bottom: 12px;
  }

  .legar-warning-description {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #6b6b6b;
    margin-bottom: 32px;
    display: block;
  }

  .ask-age-title {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #393939;
    margin-bottom: 24px;
    display: block;
  }

  .ask-age-buttons {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    & > .ant-space-item:last-child .ant-space {
      flex-direction: column;
    }

    & > .ant-space-item:first-child .ant-image img.ant-image-img {
      width: 150px;
      height: auto !important;
    }

    & > .ant-space-item:first-child .ant-image {
      width: auto !important;
      height: auto !important;
    }

    .legar-warning-title {
      margin-top: 0;
    }
  }
`

export const StyledLogoImage = styled(Image)`
  @media (max-width: 767px) {
    width: 180px;
    margin: 0 auto;
  }
`
