import React, { createContext, useState, useContext, useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import api from '@/services/api'
import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import { SimpleProductProps } from '@/Props/SimpleProductProps'
import { RetailerProductProps } from '@/Props/RetailerProductProps'
import { useSession } from 'next-auth/client'
import { RetailerProps } from '@/Props/RetailerProps'
import { getDistance } from 'geolib'
import { useClient } from './ClientContext'
import { CartProps } from '@/Props/CartProps'
import { VerifyIfProductInPromotion } from '@/utils/VerifyIfProductInPromotion'
import { AutoDestroyModal } from '@/components/shared/AutoDestroyModal'

//import api from '../services/api'

interface CartItemProps {
  configurableId: number
  retailerProductId: number
  simpleProductId: number
  qty: number
  stock: number
}

interface LoadItemsInfoReturnProps {
  key: number
  configurableProduct: ConfigurableProductProps
  simpleProduct: SimpleProductProps
  retailerProduct: RetailerProductProps
  qty: number
}

export type CartContextType = {
  loading: boolean
  addToCart(cartItem: CartItemProps, update?: boolean): void
  cartId: string
  items: CartItemProps[]
  totalItems(): number
  /* totalToPay(): number */
  loadItemsInfo(): Promise<LoadItemsInfoReturnProps[]>
  clearCart(): void
  removeItem(itemId): void
  retailerCartId: string
  shippingType: string
  shippingTime: string
  shippingDay: string
  setCartShippingType(shippingType: string): void
  setCartShippingTime(shippingTime: string): void
  setCartRetailerId(cartRetailerId: string): void
  setCartShippingDay(shippingDay: string): void
  calculateShippingPrices: (
    retailer: RetailerProps,
    cartWeight: number,
    cartPrice: number
  ) => Promise<number>
  updateItemInCart(cartId: string, cartItem: CartItemProps)
  promoCode: string
  changePromoCode(promoCode: string): void
  cartNote: string
  changeCartNote(cartNote: string): void
  isPresent: boolean
  changeIsPresent(isPresent: boolean): void
  isMiniCartVisible: boolean
}

const CartContext = createContext({} as CartContextType)

export const CartProvider = ({ children }) => {
  const { latitude, longitude, address, addressId } = useClient()
  const [session] = useSession()
  const [items, setItems] = useState<CartItemProps[]>(
    Cookies.get('cart') ? JSON.parse(Cookies.get('cart')) : []
  )
  const [cartId, setCartId] = useState(Cookies.get('cart-id'))
  const [loading, setLoading] = useState(true)
  const [promoCode, setPromoCode] = useState(Cookies.get('promo-code'))
  const [retailerId, setRetailerId] = useState(Cookies.get('cart-retailer-id'))
  const [shippingType, setShippingType] = useState(Cookies.get('cart-shipping-type'))
  const [shippingTime, setShippingTime] = useState(Cookies.get('cart-shipping-time'))
  const [shippingDay, setShippingDay] = useState(Cookies.get('cart-shipping-day'))
  const [cartNote, setCartNote] = useState(Cookies.get('cart-note'))
  const [isPresent, setIsPresent] = useState(Cookies.get('cart-present') ? true : false)
  const [isMiniCartVisible, setIsMiniCartVisible] = useState(false)

  const createNewCart = useCallback(() => {
    api
      .post<CartProps>('/carts', {
        customer_id: session.customer.id,
        address: address,
        retailer_id: retailerId,
        address_id: addressId,
      })
      .then(({ data: cart }) => {
        setCartId(cart.id)
        Cookies.set('cart-id', cart.id)
      })
  }, [address, addressId, retailerId, session])

  useEffect(() => {
    if (session) {
      if (session.accessToken) {
        api.defaults.headers.Authorization = `Bearer ${session.accessToken}`
      }
      if (session.customer) {
        try {
          api
            .get<CartProps>(`/customers/${session.customer.id}/cart`)
            .then((response) => {
              if (response.data.id) {
                if (response.data.id != cartId) {
                  if (
                    (addressId || retailerId) &&
                    (addressId !== response.data.address_id ||
                      retailerId !== response.data.retailer_id?.toString())
                  ) {
                    api.delete(`/carts/${response.data.id}`).then(() => {
                      createNewCart()
                    })
                  } else {
                    setCartId(response.data.id)
                    Cookies.set('cart-id', response.data.id)
                  }
                }
              } else {
                if (address && address !== '') {
                  createNewCart()
                }
              }
            })
            .catch((error) => {
              if (error.response.data.code == 'E_ROW_NOT_FOUND') {
                createNewCart()
              }
            })
        } catch (error) {}
      }
    }
    setLoading(false)
    /* } */
  }, [session, cartId, address, retailerId, addressId, createNewCart])

  useEffect(() => {
    const syncCartItemsToDataBase = async () => {
      await Promise.all(
        items.map(async (item) => {
          await updateItemInCart(cartId, item)
        })
      )
      api.get<CartItemProps[]>(`/carts/${cartId}/items`).then(({ data }) => {
        Cookies.set('cart', JSON.stringify(data))
        setItems(data)
        setLoading(false)
      })
    }
    if (cartId) {
      syncCartItemsToDataBase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId])

  const setCartRetailerId = (cartRetailerId: string): void => {
    setRetailerId(cartRetailerId)
    Cookies.set('cart-retailer-id', cartRetailerId)
  }

  const setCartShippingType = (shippingType: string): void => {
    setShippingType(shippingType)
    Cookies.set('cart-shipping-type', shippingType)
  }

  const setCartShippingTime = (shippingTime: string): void => {
    setShippingTime(shippingTime)
    Cookies.set('cart-shipping-time', shippingTime)
  }

  const setCartShippingDay = (shippingDay: string): void => {
    setShippingDay(shippingDay)
    Cookies.set('cart-shipping-day', shippingDay)
  }

  const changeCartNote = (cartNote: string): void => {
    setCartNote(cartNote)
    Cookies.set('cart-note', cartNote)
  }

  const changePromoCode = (promoCode: string): void => {
    setPromoCode(promoCode)
    if (promoCode === null) {
      Cookies.remove('promo-code')
    } else {
      Cookies.set('promo-code', promoCode)
    }
  }

  const changeIsPresent = (isPresent: boolean): void => {
    if (isPresent) {
      setIsPresent(true)
      Cookies.set('cart-present', 'true')
    } else {
      setIsPresent(false)
      Cookies.remove('cart-present')
    }
  }

  const updateItemInCart = async (cartid: string, cartItem: CartItemProps) => {
    await api.post(`/carts/${cartid}/items`, {
      retailer_product_id: cartItem.retailerProductId,
      qty: cartItem.qty,
      cart_id: cartId,
    })
  }

  const addToCart = async (cartItem: CartItemProps, update = false): Promise<void> => {
    let showErrorMessage = false
    setIsMiniCartVisible(true)
    const existCartItem = items.findIndex((item) => {
      return (
        item.retailerProductId == cartItem.retailerProductId &&
        item.simpleProductId == cartItem.simpleProductId
      )
    })
    if (session) {
      if (cartId === '') {
        const { data: cart } = await api.post<CartProps>('/carts', {
          customer_id: session.customer.id,
          address: address,
          retailer_id: retailerId,
          address_id: addressId,
        })
        setCartId(cart.id)
        Cookies.set('cart-id', cart.id)
        if (existCartItem != -1) {
          items[existCartItem].qty = cartItem.qty
          if (items[existCartItem].qty <= cartItem.stock) {
            await updateItemInCart(cart.id, {
              ...cartItem,
              qty: update ? cartItem.qty : cartItem.qty,
            })
          } else {
            await updateItemInCart(cart.id, {
              ...cartItem,
              qty: update ? cartItem.stock : cartItem.stock,
            })

            showErrorMessage = true
          }
        } else {
          await updateItemInCart(cart.id, cartItem)
        }
      } else {
        if (existCartItem != -1) {
          items[existCartItem].qty = cartItem.qty

          if (items[existCartItem].qty <= cartItem.stock) {
            await updateItemInCart(cartId, {
              ...cartItem,
              qty: update ? cartItem.qty : cartItem.qty,
            })
          } else {
            await updateItemInCart(cartId, {
              ...cartItem,
              qty: update ? cartItem.stock : cartItem.stock,
            })

            showErrorMessage = true
          }
        } else {
          if (cartItem.qty <= cartItem.stock) {
            await updateItemInCart(cartId, cartItem)
          } else {
            await updateItemInCart(cartId, {
              ...cartItem,
              qty: cartItem.stock,
            })
          }
        }
      }
    }

    if (existCartItem != -1) {
      items[existCartItem].qty = cartItem.qty
      const newItems = items.slice() //copy the array
      if (update) {
        if (items[existCartItem].qty <= cartItem.stock) {
          newItems[existCartItem].qty = cartItem.qty
        } else {
          newItems[existCartItem].qty = cartItem.stock

          showErrorMessage = true
        }
      } else {
        if (items[existCartItem].qty <= cartItem.stock) {
          newItems[existCartItem].qty = cartItem.qty
        } else {
          newItems[existCartItem].qty = cartItem.stock

          showErrorMessage = true
        }
      }

      Cookies.set('cart', JSON.stringify(newItems))
      setItems(newItems)
    } else {
      if (cartItem.qty <= cartItem.stock) {
        Cookies.set('cart', JSON.stringify([cartItem, ...items]))
        setItems([cartItem, ...items])
      } else {
        Cookies.set('cart', JSON.stringify([{ ...cartItem, qty: cartItem.stock }, ...items]))
        setItems([{ ...cartItem, qty: cartItem.stock }, ...items])
      }
    }
    if (showErrorMessage) {
      AutoDestroyModal({
        type: 'error',
        title: 'A quantidade é maior que o stock',
        content: 'Não pode adicionar uma quantidade maior que o stock disponível',
      })
    }
  }

  const totalItems = (): number => {
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue

    return items.map((el) => el.qty).reduce(reducer, 0)
  }

  const loadItemsInfo = async (): Promise<LoadItemsInfoReturnProps[]> => {
    const itemsInfo = await Promise.all(
      items.map(async (item) => {
        const { data } = await api.get<ConfigurableProductProps>(
          `/configurable-products/${item.configurableId}`
        )
        const simpleProduct = data.simpleProducts.find(
          (simpleProduct) => simpleProduct.id == item.simpleProductId
        )

        const retailerProduct = simpleProduct.retailerProducts.find(
          (retailerProduct) => retailerProduct.id == item.retailerProductId
        )

        const isProductInPromotion = VerifyIfProductInPromotion({
          specialPrice: retailerProduct.special_price,
          specialPriceBeginDate: retailerProduct.special_price_from?.toString(),
          specialPriceEndDate: retailerProduct.special_price_to?.toString(),
        })

        retailerProduct.price = isProductInPromotion
          ? retailerProduct.special_price
          : retailerProduct.price

        return {
          key: retailerProduct.id,
          configurableProduct: data,
          simpleProduct,
          retailerProduct,
          qty: item.qty,
        }
      })
    )
    return itemsInfo
  }

  /* const totalToPay = (): number => {
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue

    return items.map((el) => el.qty * el.price).reduce(reducer, 0)
  } */

  const removeItem = async (itemId) => {
    const cartItems = items.filter((item) => {
      if (item.retailerProductId == itemId) {
        if (session) {
          api.delete(`/carts/${cartId}/items/${item.retailerProductId}`)
        } else {
          return false
        }
      } else {
        return item.retailerProductId != itemId
      }
    })
    if (cartItems.length == 0) {
      clearCart()
    }
    setItems(cartItems)
    Cookies.set('cart', JSON.stringify(cartItems))
  }

  const clearCart = () => {
    Cookies.remove('cart')
    Cookies.remove('cart-retailer-id')
    Cookies.remove('cart-shipping-type')
    Cookies.remove('cart-shipping-time')
    Cookies.remove('cart-shipping-day')
    Cookies.remove('promo-code')
    Cookies.remove('cart-note')
    Cookies.remove('cart-present')
    Cookies.remove('cart-id')
    setItems([])
    setRetailerId(null)
    setShippingTime(null)
    setShippingType(null)
    setPromoCode(null)
    setCartNote('')
    setIsPresent(false)
    setCartId('')
  }

  const calculateShippingPrices = async (
    retailer: RetailerProps,
    cartWeight: number,
    cartPrice: number
  ): Promise<number> => {
    let shippingPrice = 0
    if (shippingType !== 'pickup' && retailer && shippingType && shippingTime) {
      const distanceInMeters = getDistance(
        { latitude, longitude },
        {
          latitude: retailer.latitude,
          longitude: retailer.longitude,
        }
      )
      const distanceInKm = distanceInMeters / 1000
      const response = await api.get('/shipping/price', {
        params: {
          shippingType,
          shippingTime,
          shippingDay,
          weight: cartWeight,
          distance: distanceInKm,
          retailerId: retailer.id,
          cartPrice,
        },
      })
      shippingPrice = response.data
    }

    return shippingPrice
  }

  useEffect(() => {
    if (isMiniCartVisible) {
      setTimeout(() => {
        setIsMiniCartVisible(false)
      }, 5000)
    }
  }, [isMiniCartVisible])

  return (
    <CartContext.Provider
      value={{
        loading,
        addToCart,
        items,
        totalItems,
        loadItemsInfo,
        clearCart,
        cartId,
        removeItem,
        retailerCartId: retailerId,
        shippingType,
        shippingTime,
        shippingDay,
        setCartShippingType,
        setCartShippingTime,
        setCartRetailerId,
        calculateShippingPrices,
        setCartShippingDay,
        updateItemInCart,
        promoCode,
        changePromoCode,
        cartNote,
        changeCartNote,
        isPresent,
        changeIsPresent,
        isMiniCartVisible,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
