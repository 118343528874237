import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import api from '@/services/api'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState, useRef, useEffect } from 'react'
import { StyledDropDow, StyledSearch, StyledSearchInput, StyledSearchInputWrapper } from './styles'

const SearchInput = () => {
  const router = useRouter()
  const node = useRef() as React.MutableRefObject<HTMLInputElement>
  const [isInputActive, setIsInputActive] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [listWithSearchResults, setListWithSearchResults] = useState<ConfigurableProductProps[]>([])
  const [isResultsVisible, setIsResultsVisible] = useState(false)

  const handleSearchInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value
    setInputValue(searchTerm)
    if (searchTerm.length >= 3) {
      setIsResultsVisible(true)
      const { data: searchProductsResult } = await api.get<ConfigurableProductProps[]>(
        `/search-products`,
        {
          params: {
            q: searchTerm,
          },
        }
      )
      setListWithSearchResults(searchProductsResult)
    } else {
      setIsResultsVisible(false)
      setListWithSearchResults([])
    }
  }

  /*const handleSearchIconClick = () => {
    setIsInputActive(!isInputActive)
  }*/

  const handleClickOutside = (e) => {
    if (node != undefined && node.current != undefined) {
      if (node.current?.contains(e.target)) {
        // inside click
        return
      }
    }
    // outside click
    setInputValue('')
    setIsInputActive(true)
    setIsResultsVisible(false)
    setListWithSearchResults([])
  }

  useEffect(() => {
    if (isInputActive) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isInputActive])

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value
    router.push(`/search?q=${searchTerm}`)
  }

  return (
    <StyledSearchInputWrapper ref={node} $isInputActive={isInputActive}>
      <StyledSearchInput
        onChange={handleSearchInputChange}
        value={inputValue}
        allowClear
        $isInputActive={isInputActive}
        onPressEnter={handleOnPressEnter}
        placeholder="Pesquisar cerveja, vinho..."
      ></StyledSearchInput>
      <StyledSearch
        //onClick={handleSearchIconClick}
        size={24}
        $isInputActive={isInputActive}
      ></StyledSearch>
      <StyledDropDow direction="vertical" size={0}>
        {isResultsVisible && listWithSearchResults.length == 0 && <p>Sem Resultados ...</p>}
        {listWithSearchResults.map((listProduct, index) => {
          return (
            <Link key={index} href={`/product/${listProduct.slug}`}>
              <a> {listProduct.name} </a>
            </Link>
          )
        })}
      </StyledDropDow>
    </StyledSearchInputWrapper>
  )
}

export { SearchInput }
