import { createContext, ReactNode, useContext, useState } from 'react'
import Cookies from 'js-cookie'
import { Modal } from 'antd'
import { MapsPlacesAutocomplete } from '@/components/shared/MapsPlacesAutocomplete'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import { useCart } from './cart'

interface ClientProviderProps {
  children: ReactNode
}

interface ClientContextData {
  address: string
  addressId: string
  latitude: string
  longitude: string
  city: string
  country: string
  district: string
  postalCode: string
  changeClientAddress: (newAddress: string) => void
  changeClientAddressId: (newAddressId: string) => void
  changeClientCoordinates: ({ latitude, longitude }: ChangeClientCoordinatesProps) => void
  changeClientCity: (newCity: string) => void
  changeClientCountry: (newCountry: string) => void
  changeClientDistrict: (newAddress: string) => void
  changeClientPostalCode: (newAddress: string) => void
  showChangeAddressModal: () => void
  changeAddressModal: () => JSX.Element
  isChangeAddressModalVisible: boolean
  closePopup: () => void
}

interface ChangeClientCoordinatesProps {
  longitude: string
  latitude: string
}

export const ClientContext = createContext({} as ClientContextData)

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const [address, setAddress] = useState(Cookies.get('clientAddress'))
  const [addressId, setAddressId] = useState(Cookies.get('clientAddressId'))
  const [latitude, setLatitude] = useState(Cookies.get('clientLatitude'))
  const [longitude, setLongitude] = useState(Cookies.get('clientLongitude'))
  const [city, setCity] = useState(Cookies.get('clientCity'))
  const [country, setCountry] = useState(Cookies.get('clientCountry'))
  const [district, setDistrict] = useState(Cookies.get('clientDistrict'))
  const [postalCode, setPostalCode] = useState(Cookies.get('clientPostalCode'))
  const [isChangeAddressModalVisible, setIsChangeAddressModalVisible] = useState(false)
  const [modalSearchAddress, setModalSearchAddress] = useState('')

  const changeClientAddress = (newAddress: string) => {
    setAddress(newAddress)
    Cookies.set('clientAddress', newAddress)
  }

  const changeClientAddressId = (newAddressId: string) => {
    setAddressId(newAddressId)
    Cookies.set('clientAddressId', newAddressId)
  }

  const changeClientCoordinates = ({ latitude, longitude }: ChangeClientCoordinatesProps) => {
    setLatitude(latitude)
    setLongitude(longitude)
    Cookies.set('clientLatitude', latitude)
    Cookies.set('clientLongitude', longitude)
  }

  const changeClientCity = (newCity: string) => {
    setCity(newCity)
    Cookies.set('clientCity', newCity)
  }

  const changeClientDistrict = (newDistrict: string) => {
    setDistrict(newDistrict)
    Cookies.set('clientDistrict', newDistrict)
  }

  const changeClientPostalCode = (newPostalCode: string) => {
    setPostalCode(newPostalCode)
    Cookies.set('clientPostalCode', newPostalCode)
  }

  const changeClientCountry = (newCountry: string) => {
    setCountry(newCountry)
    Cookies.set('clientCountry', newCountry)
  }

  const showChangeAddressModal = () => {
    setIsChangeAddressModalVisible(true)
  }

  const closePopup = () => {
    setIsChangeAddressModalVisible(false)
  }

  const handleChangeAddress = (address: string) => {
    setModalSearchAddress(address)
  }

  const handleSelectAddress = async (address: string, placeId: string) => {
    const response = await googleMapsAutocompleteParser(placeId)

    changeClientAddressId(placeId)
    changeClientAddress(response.formatted_address)
    changeClientCity(response.city)
    changeClientDistrict(response.district)
    changeClientCountry(response.country)
    changeClientPostalCode(response.postal_code)
    changeClientCoordinates({
      latitude: response.lat.toString(),
      longitude: response.lng.toString(),
    })
    setIsChangeAddressModalVisible(false)
    setModalSearchAddress('')
  }

  const changeAddressModal = () => (
    <Modal
      visible={isChangeAddressModalVisible}
      title="Onde estás?"
      onCancel={() => setIsChangeAddressModalVisible(false)}
      footer={null}
      className="location-modal"
    >
      <MapsPlacesAutocomplete
        address={modalSearchAddress}
        handleSelect={handleSelectAddress}
        handleChange={handleChangeAddress}
      />
    </Modal>
  )

  return (
    <ClientContext.Provider
      value={{
        address,
        latitude,
        longitude,
        city,
        country,
        addressId,
        changeClientAddress,
        changeClientAddressId,
        changeClientCoordinates,
        changeClientCity,
        changeClientCountry,
        isChangeAddressModalVisible,
        showChangeAddressModal,
        changeAddressModal,
        district,
        postalCode,
        changeClientDistrict,
        changeClientPostalCode,
        closePopup,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClient = () => useContext(ClientContext)
