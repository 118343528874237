import Link from 'next/link'
import {
  CookiesBackground,
  CookiesButton,
  CookiesDescription,
  CookiesTitle,
  CookiesWrapper,
} from './styles'

interface CookiesPopupProps {
  buttonCalback: () => void
}

const CookiesPopup = ({ buttonCalback }: CookiesPopupProps) => {
  return (
    <CookiesBackground>
      <CookiesWrapper>
        <CookiesTitle>Política de privacidade</CookiesTitle>
        <CookiesDescription>
          Este site usa cookies para melhorar a experiência de navegação e fornecer mais
          funcionalidades.
          <Link href="/privacy-policy">
            <a> Saber mais </a>
          </Link>
        </CookiesDescription>
        <CookiesButton onClick={buttonCalback} type="primary">
          Aceitar e continuar
        </CookiesButton>
      </CookiesWrapper>
    </CookiesBackground>
  )
}

export { CookiesPopup }
