import React, { useEffect, useState } from 'react'
import { Badge, Col, Button, Dropdown } from 'antd'
import Link from 'next/link'
import {
  StyledTopHeader,
  StyledBtnTopHead,
  StyledTextTopHead,
  StyledMiddleHeader,
  /* StyledDropdown,
  StyledDropdownAnchor,
  StyledDropdownIcon, */
  StyledAccountBtn,
  StyleShoppingFilled,
  StyledMenuIcon,
  StyledHeader,
  StyledHeaderMenu,
  StyledLeftContent,
  StyledLogoImage,
  StyledRightContent,
  StyledFooter,
  StyledFooterSpace,
  StyledRow,
  StyledListItem,
  StyledListItemHeader,
  StyledListTitle5,
  StyledAnchor,
  StyledText,
  StyledTextCopyrightAnchor,
  StyledLogoSpace,
  CustomerAvatar,
  FolloUsWrapper,
  FolloUsWrapperTitle,
  PaymentMethodsWrapper,
  PaymentMethodsTitle,
  FooterDivider,
  StyledFooterBottom,
  StyledCopyright,
  StyledTermsAndConditions,
  StyledLivroReclamacoes,
  StyledList,
  StyledCloseIcon,
} from './styles'
import { useCart } from '@/contexts/cart'
//import api from '@/services/api'
import { Layout, Menu, Space } from 'antd'
import { StyledContainer } from '../shared/Container/styles'
import api from '@/services/api'
import { CategoryProps } from '@/Props/CategoryProps'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { SearchInput } from '../shared/SearchInput'
import { useSession } from 'next-auth/client'
import { RiUserFill } from 'react-icons/ri'
import Text from 'antd/lib/typography/Text'
import { MiniCart } from '../shared/MiniCart'
import Cookies from 'js-cookie'
import { LegalWarning } from '../shared/LegalWarning'
import SubMenu from 'antd/lib/menu/SubMenu'
import { signOut } from 'next-auth/client'
import styled from 'styled-components'
import { CookiesPopup } from '@/components/shared/CookiesPopup'

export const StyledMenuBtn = styled(Button)`
  height: auto;
  padding: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`

const { Content } = Layout

/* const menu = (
  <Menu>
    <Menu.Item>PT</Menu.Item>
    <Menu.Item>EN</Menu.Item>
    <Menu.Item>FR</Menu.Item>
    <Menu.Item>ES</Menu.Item>
  </Menu>
) */

const Logout = () => {
  Cookies.remove('clientAddress')
  Cookies.remove('cart-shipping-type')
  Cookies.remove('customer')
  Cookies.remove('clientLongitude')
  Cookies.remove('cart')
  Cookies.remove('customer-token')
  Cookies.remove('clientLatitude')
  Cookies.remove('cart-id')
  Cookies.remove('cart-retailer-id')
  Cookies.remove('cart-shipping-time')
  signOut()
}

const optionsMenu = (
  <Menu>
    <Menu.Item>
      <Link href={`/customer/account`}>
        <a>
          <Text>A minha conta</Text>
        </a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link href={`/customer/account/orders`}>
        <a>
          <Text>As minhas encomendas</Text>
        </a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <StyledMenuBtn type="link" onClick={() => Logout()} danger>
        Terminar sessão
      </StyledMenuBtn>
    </Menu.Item>
  </Menu>
)

const renderMenuImage = (category) => {
  if (category.banner && category.banner !== null) {
    return (
      <div className="submenu-image">
        <img src={category.banner} alt={category.name} />
      </div>
    )
  }

  return null
}

const renderMenuItem = (category) => {
  if (category.is_sub_category) {
    return null
  }

  if (category.childCategories) {
    return (
      <Menu.Item key={`category-${category.slug}`} className="has-submenu">
        <Link href={`/category/${category.slug}`}>
          <a>{category.name}</a>
        </Link>
        <div className="submenu-dropdown">
          <div className="container">
            <div className="submenu-row">
              <div className="submenu-list">
                <div className="submenu-title">{category.name}</div>
                <ul>
                  {category.childCategories.map((subcategory) => (
                    <li key={subcategory.slug}>
                      <Link href={`/category/${subcategory.slug}`}>
                        <a>{subcategory.name}</a>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link href={`/category/${category.slug}`}>
                      <a className="see-all">Ver tudo</a>
                    </Link>
                  </li>
                </ul>
              </div>
              {renderMenuImage(category)}
            </div>
          </div>
        </div>
      </Menu.Item>
    )
  }

  return (
    <Menu.Item key={category.slug}>
      <Link href={`/category/${category.slug}`}>
        <a>{category.name}</a>
      </Link>
    </Menu.Item>
  )
}

const PageLayout: React.FC = ({ children }) => {
  const router = useRouter()
  const { totalItems, isMiniCartVisible } = useCart()
  const [session] = useSession()
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const [customHaveLegalAge, setCustomHaveLegalAge] = useState(
    Cookies.get('legal-age') ? true : false
  )
  const [customerHaveAcceptCookies, setCustomerAcceptCookies] = useState(
    Cookies.get('accept-cookies') ? true : false
  )
  const [aboutUsItems, setAboutUsItems] = useState(false)
  const [contactItems, setContactItems] = useState(false)
  const [partnerItems, setPartnerItems] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  useEffect(() => {
    api.get<CategoryProps[]>('categories').then(({ data: categories }) => {
      setCategories(categories)
    })
  }, [])

  const handleAcceptLegarWarning = () => {
    setCustomHaveLegalAge(true)
    Cookies.set('legal-age', 'true')
  }

  const ShowAboutUsItems = () => {
    if (window.innerWidth < 767 && !aboutUsItems) {
      setAboutUsItems(true)
    } else {
      setAboutUsItems(false)
    }
  }

  const ShowContactItems = () => {
    if (window.innerWidth < 767 && !contactItems) {
      setContactItems(true)
    } else {
      setContactItems(false)
    }
  }

  const ShowPartnerItems = () => {
    if (window.innerWidth < 767 && !partnerItems) {
      setPartnerItems(true)
    } else {
      setPartnerItems(false)
    }
  }

  const OpenMenuMobile = () => {
    setOpenMenu(!openMenu)
  }
  interface FooterLinksProps {
    title: string
    link: string
  }
  const aboutUs: FooterLinksProps[] = [
    {
      title: 'O marketplace',
      link: '/marketplace',
    },
    {
      title: "FAQ'S",
      link: '/faqs',
    },
    {
      title: 'Blog',
      link: '/blog',
    },
  ]
  const contactUs: FooterLinksProps[] = [
    {
      title: 'Apoio na compra',
      link: '/help-buying',
    },
    {
      title: 'Devoluções',
      link: '/returns-form',
    },
  ]
  const beOurPartner: FooterLinksProps[] = [
    {
      title: 'Responsabilidade social',
      link: '/social-responsability',
    },
    {
      title: 'Retalho',
      link: '/retailers/become-retailer',
    },
    {
      title: 'Marcas',
      link: '/brands',
    },
  ]

  const handleAcceptCookies = () => {
    setCustomerAcceptCookies(true)
    Cookies.set('accept-cookies', 'true')
  }

  return (
    <Layout className="layout">
      {!customHaveLegalAge && <LegalWarning acceptLegalWarning={handleAcceptLegarWarning} />}
      {!customerHaveAcceptCookies && <CookiesPopup buttonCalback={handleAcceptCookies} />}
      <StyledTopHeader className="top-information-block" direction="horizontal" align="center">
        <StyledBtnTopHead type="primary" size="small" href="/marketplace">
          Como funciona a SW!G
        </StyledBtnTopHead>
        <StyledTextTopHead>Cerveja, vinho e licores entregues à tua porta</StyledTextTopHead>
        <StyledBtnTopHead
          type="primary"
          size="small"
          onClick={() => router.push('/retailers/become-retailer')}
        >
          Torna-te um retalhista SW!G
        </StyledBtnTopHead>
      </StyledTopHeader>
      <StyledHeader>
        <StyledMiddleHeader className="middle-header" size={0}>
          <StyledLeftContent className="lef-content" size={42}>
            <div className="menu-mobile">
              <Button type="link" onClick={() => OpenMenuMobile()}>
                <StyledMenuIcon />
              </Button>
              <div className={openMenu ? 'menu-mobile-items active' : 'menu-mobile-items'}>
                <Button type="link" onClick={() => OpenMenuMobile()}>
                  <StyledCloseIcon />
                </Button>
                <SearchInput />
                <StyledHeaderMenu theme="light" mode="inline">
                  {categories.map((category, index) => {
                    if (category.childCategories?.length > 0) {
                      return (
                        <SubMenu key={index} title={category.name}>
                          {category.childCategories.map((subcategory) => (
                            <Menu.Item key={`${category.slug}-${index}-${subcategory.slug}`}>
                              <Link href={`/category/${subcategory.slug}`}>
                                <a>{subcategory.name}</a>
                              </Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      )
                    } else if (!category.is_sub_category) {
                      return (
                        <Menu.Item key={index}>
                          <Link href={`/category/${category.slug}`}>
                            <a>{category.name}</a>
                          </Link>
                        </Menu.Item>
                      )
                    }
                  })}
                </StyledHeaderMenu>
                {session?.customer ? (
                  <Link href="/customer/account">
                    <a className="account-btn">
                      <Space>
                        <CustomerAvatar size="large" icon={<RiUserFill />} />
                        <Text> {session.customer.name} </Text>
                      </Space>
                    </a>
                  </Link>
                ) : (
                  <StyledAccountBtn type="primary" className="account-btn no-login">
                    <Link href="/customer/login">
                      <a>A minha conta</a>
                    </Link>
                  </StyledAccountBtn>
                )}
              </div>
            </div>
            <Space className="logo" align="center">
              <Link href="/">
                <a aria-label="Voltar para homepage">
                  <StyledLogoImage
                    width={117}
                    height={40}
                    preview={false}
                    src="/assets/images/swig-logo.svg"
                    alt="Swig logo"
                  ></StyledLogoImage>
                </a>
              </Link>
            </Space>
            <StyledHeaderMenu theme="light" mode="horizontal">
              {categories.map((category) => renderMenuItem(category))}
            </StyledHeaderMenu>
          </StyledLeftContent>
          <StyledRightContent className="right-content" size="middle">
            <SearchInput />
            {/* <StyledDropdown overlay={menu}>
              <StyledDropdownAnchor className="ant-dropdown-link">
                PT <StyledDropdownIcon></StyledDropdownIcon>
              </StyledDropdownAnchor>
            </StyledDropdown> */}

            {session?.customer ? (
              <Dropdown overlay={optionsMenu}>
                <a className="account-btn">
                  <Space>
                    <CustomerAvatar size="large" icon={<RiUserFill />} />
                    <Text>{session.customer.name}</Text>
                  </Space>
                </a>
              </Dropdown>
            ) : (
              <StyledAccountBtn type="primary">
                <Link href="/customer/login">
                  <a>A minha conta</a>
                </Link>
              </StyledAccountBtn>
            )}

            <Space style={{ position: 'relative' }}>
              <Link href="/cart">
                <a>
                  <Badge
                    count={totalItems()}
                    offset={[0, 20]}
                    style={{ backgroundColor: '#F16749' }}
                  >
                    <StyleShoppingFilled
                      color="#F16749"
                      style={{ fontSize: '25px', color: '#F16749' }}
                    />
                  </Badge>
                </a>
              </Link>
              {isMiniCartVisible && <MiniCart />}
            </Space>
          </StyledRightContent>
        </StyledMiddleHeader>
      </StyledHeader>
      <Content>{children}</Content>
      <StyledFooter>
        <StyledContainer>
          <StyledFooterSpace>
            <Col lg={6}>
              <StyledLogoSpace>
                <Link href="/">
                  <a aria-label="Voltar para homepage">
                    <Image
                      width={167}
                      height={91}
                      src="/assets/images/footer-logo.svg"
                      alt="Swig logo"
                    ></Image>
                  </a>
                </Link>
              </StyledLogoSpace>
            </Col>
            <Col lg={12}>
              <StyledRow
                justify="space-between"
                gutter={[
                  { md: 20, lg: 30, xl: 10 },
                  { xs: 8, sm: 8, md: 30, lg: 0 },
                ]}
              >
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <StyledList
                    size="small"
                    header={
                      <StyledListItemHeader onClick={() => ShowAboutUsItems()}>
                        <StyledListTitle5 level={5}>Sobre nós</StyledListTitle5>
                      </StyledListItemHeader>
                    }
                    $active={aboutUsItems}
                    dataSource={aboutUs}
                    renderItem={(item: FooterLinksProps) => (
                      <StyledListItem>
                        <Link href={item.link}>
                          <StyledAnchor>{item.title}</StyledAnchor>
                        </Link>
                      </StyledListItem>
                    )}
                  ></StyledList>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={9}>
                  <StyledList
                    size="small"
                    header={
                      <StyledListItemHeader onClick={() => ShowContactItems()}>
                        <StyledListTitle5 level={5}>Entre em contacto</StyledListTitle5>
                      </StyledListItemHeader>
                    }
                    $active={contactItems}
                    dataSource={contactUs}
                    renderItem={(item: FooterLinksProps) => (
                      <StyledListItem>
                        <Link href={item.link}>
                          <StyledAnchor>{item.title}</StyledAnchor>
                        </Link>
                      </StyledListItem>
                    )}
                  ></StyledList>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={9}>
                  <StyledList
                    size="small"
                    header={
                      <StyledListItemHeader onClick={() => ShowPartnerItems()}>
                        <StyledListTitle5 level={5}>Seja nosso parceiro</StyledListTitle5>
                      </StyledListItemHeader>
                    }
                    $active={partnerItems}
                    dataSource={beOurPartner}
                    renderItem={(item: FooterLinksProps) => (
                      <StyledListItem>
                        <Link href={item.link}>
                          <StyledAnchor>{item.title}</StyledAnchor>
                        </Link>
                      </StyledListItem>
                    )}
                  ></StyledList>
                </Col>
              </StyledRow>
            </Col>
            <Col lg={6}>
              <Space direction="vertical" size={24}>
                <FolloUsWrapper direction="vertical">
                  <FolloUsWrapperTitle>Siga-nos</FolloUsWrapperTitle>
                  <Space size={16}>
                    <Link href="https://www.facebook.com/">
                      <a target="_blank" rel="noreferrer">
                        Facebook
                      </a>
                    </Link>
                    <Link href="https://www.instagram.com/swig-marketplace">
                      <a target="_blank" rel="noreferrer">
                        Instagram
                      </a>
                    </Link>
                    <Link href="https://www.linkedin.com/company/swig-marketplace/">
                      <a target="_blank" rel="noreferrer">
                        Linkedin
                      </a>
                    </Link>
                  </Space>
                </FolloUsWrapper>
                <PaymentMethodsWrapper direction="vertical">
                  <PaymentMethodsTitle>Opções de pagamento</PaymentMethodsTitle>
                  <Space size={12}>
                    <img src="/assets/images/visa.svg" alt="Visa" />
                    <img src="/assets/images/mastercard.svg" alt="MasterCard" />
                    <img src="/assets/images/mbway.svg" alt="MBWay" />
                    <img src="/assets/images/paypal.svg" alt="Paypal" />
                    <img src="/assets/images/multibanco.svg" alt="Multibanco" />
                  </Space>
                </PaymentMethodsWrapper>
              </Space>
            </Col>
          </StyledFooterSpace>
          <FooterDivider />
          <StyledFooterBottom>
            <StyledCopyright style={{ textAlign: 'left' }}>
              <StyledText>
                <StyledText>@2021 SW!G.</StyledText> Design
                <StyledTextCopyrightAnchor
                  href="https://www.lisbonproject.com/pt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lisbon Project
                </StyledTextCopyrightAnchor>
                {' | '}
                Desenvolvimento
                <StyledTextCopyrightAnchor
                  href="https://www.primariu.com/pt"
                  target="_blank"
                  rel="noreferrer"
                >
                  PRIMARIU
                </StyledTextCopyrightAnchor>
              </StyledText>
            </StyledCopyright>
            <StyledTermsAndConditions size={30}>
              <Link href="/privacy-policy">
                <a>Política de privacidade</a>
              </Link>
              <Link href="/terms-and-conditions">
                <a>Termos e condições</a>
              </Link>
            </StyledTermsAndConditions>
            <StyledLivroReclamacoes style={{ textAlign: 'right' }}>
              <Link href="https://www.livroreclamacoes.pt/inicio">
                <a target="_blank" rel="noreferrer" aria-label="Livro de reclamações">
                  <Image
                    width={163}
                    height={64}
                    src="/assets/images/livro_reclamacoes-white.png"
                    alt="Livro de reclamações"
                  ></Image>
                </a>
              </Link>
            </StyledLivroReclamacoes>
          </StyledFooterBottom>
        </StyledContainer>
      </StyledFooter>
    </Layout>
  )
}

export default PageLayout
