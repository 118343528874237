import { SearchOutlined } from '@ant-design/icons'
import { Input, Space } from 'antd'
import styled, { css } from 'styled-components'

interface StyledSearchInputProps {
  $isInputActive: boolean
}

export const StyledSearchInputWrapper = styled.div<StyledSearchInputProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 48px;
  padding: 0 12px;
  transition: all 0.4s;
  /* max-width: 60px; */
  background: transparent;
  ${(p) =>
    p.$isInputActive &&
    css`
      max-width: 447px;
      background: #f4f4f4;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    `}
`

export const StyledSearchInput = styled(Input)<StyledSearchInputProps>`
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  opacity: 0;
  padding: 0;
  width: auto;
  transition: all 0.2s;
  pointer-events: none;
  ${(p) =>
    p.$isInputActive &&
    css`
      opacity: 1;
      padding: 10.4px 11px;
      width: auto;
      pointer-events: all;
    `}

  span,
  input {
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
`

export const StyledSearch = styled(SearchOutlined)<StyledSearchInputProps>`
  font-size: 14px;

  svg {
    transition: all 0.4s;
    font-size: 24px;
    font-size: ${(p) => p.$isInputActive && '14px'};
  }

  @media (max-width: 767px) {
    display: none;
  }
`

export const StyledDropDow = styled(Space)`
  background: #ffffff;
  /* drop-shadow/0.12+0.8+0.5 */

  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 99;

  a,
  p {
    padding: 9px 12px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #6b6b6b;
    margin: 0;
  }
`
