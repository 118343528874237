import { useCart } from '@/contexts/cart'
import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import api from '@/services/api'
import formatPriceWithCurrency from '@/utils/formatPriceWithCurrency'
import { VerifyIfProductInPromotion } from '@/utils/VerifyIfProductInPromotion'
import { Button, Image, InputNumber, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import {
  EmptyMiniCartSubTitle,
  EmptyMiniCartTitle,
  MiniCartItem,
  MiniCartItems,
  MiniCartItemsWrapper,
  MiniCartTitle,
  MiniCartWrapper,
  TotalPriceWrapper,
} from './styles'

interface CartItemProps {
  configurableId: number
  retailerProductId: number
  simpleProductId: number
  qty: number
  stock: number
}

interface MiniCartWithItemsProps {
  items: CartItemProps[]
}

const EmptyMiniCart = () => {
  return (
    <Space size={12} direction="vertical">
      <EmptyMiniCartTitle>O teu carrinho parece estar vazio...</EmptyMiniCartTitle>
      <EmptyMiniCartSubTitle>Vamos mudar isso!</EmptyMiniCartSubTitle>
      <Button type="primary" block>
        Começar a comprar
      </Button>
    </Space>
  )
}

const MiniCartWithItems = ({ items }: MiniCartWithItemsProps) => {
  const { addToCart } = useCart()

  const [cartItems, setCartItems] = useState([])

  const getTotalPrice = () => {
    let totalPrice = 0
    cartItems.map((cartItem) => {
      totalPrice += cartItem.qty * cartItem.subtotal
    })
    totalPrice
    return totalPrice
  }

  useEffect(() => {
    const fetchCartData = async () => {
      const itemsFormatted = await Promise.all(
        items.map(async (item) => {
          const { data } = await api.get<ConfigurableProductProps>(
            `/configurable-products/${item.configurableId}`
          )
          const simpleProduct = data.simpleProducts.find(
            (simpleProduct) => simpleProduct.id == item.simpleProductId
          )

          const retailerProduct = simpleProduct.retailerProducts.find(
            (retailerProduct) => retailerProduct.id == item.retailerProductId
          )

          const isProductInPromotion = VerifyIfProductInPromotion({
            specialPrice: retailerProduct.special_price,
            specialPriceBeginDate: retailerProduct.special_price_from?.toString(),
            specialPriceEndDate: retailerProduct.special_price_to?.toString(),
          })

          return {
            key: retailerProduct.id,
            product: {
              image: simpleProduct.image,
              name: data.name,
              options: `${simpleProduct.size} - ${simpleProduct.type}`,
            },
            qty: item.qty,
            subtotal: isProductInPromotion ? retailerProduct.special_price : retailerProduct.price,
            configurableId: item.configurableId,
            simpleProductId: simpleProduct.id,
          }
        })
      )
      setCartItems(itemsFormatted)
    }
    fetchCartData()
  }, [items])

  const handleInputChange = (
    configurableId: number,
    retailerProductId: number,
    simpleProductId: number,
    qty: number,
    stock: number
  ) => {
    if (qty > 0) {
      addToCart({ configurableId, retailerProductId, simpleProductId, qty, stock }, true)
    }
  }

  return (
    <MiniCartItemsWrapper>
      <MiniCartItems direction="vertical">
        {cartItems.map((item, key) => (
          <MiniCartItem key={key}>
            <Image width={47} preview={false} src={item.product.image} />
            <Space direction="vertical">
              <Text className="product-name">{item.product.name}</Text>
              <Text className="product-options">{item.product.options}</Text>
            </Space>
            <InputNumber
              defaultValue={item.qty}
              onChange={(qty: number) => {
                handleInputChange(
                  item.configurableId,
                  item.key,
                  item.simpleProductId,
                  qty,
                  item.stock
                )
              }}
            />
            <Text className="product-price">
              {formatPriceWithCurrency(item.subtotal * item.qty)}
            </Text>
          </MiniCartItem>
        ))}
      </MiniCartItems>
      <TotalPriceWrapper>
        <Text className="total-price-label"> Total </Text>
        <Text className="total-price-value"> {formatPriceWithCurrency(getTotalPrice())} </Text>
      </TotalPriceWrapper>
      <Button type="primary" block>
        <Link href="/cart">
          <a> Finalizar compra</a>
        </Link>
      </Button>
    </MiniCartItemsWrapper>
  )
}

const MiniCart = () => {
  const { items, loading } = useCart()

  if (loading) {
    return <Text> A carregar ... </Text>
  }

  return (
    <MiniCartWrapper direction="vertical" className="mini-cart">
      <MiniCartTitle>Carrinho</MiniCartTitle>
      {items.length > 0 ? <MiniCartWithItems items={items} /> : <EmptyMiniCart />}
    </MiniCartWrapper>
  )
}

export { MiniCart }
